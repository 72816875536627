import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-74b2d31c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "footer"
};
const _hoisted_2 = {
  class: "item"
};
const _hoisted_3 = {
  class: "item"
};
const _hoisted_4 = {
  class: "item"
};
const _hoisted_5 = {
  class: "item"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
    to: "/",
    class: "nav-item"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "wap-home-o"
    }), _createTextVNode(" 首页")]),
    _: 1
  })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_router_link, {
    to: "/cart",
    class: "nav-item"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "shopping-cart-o"
    }), _createTextVNode("购物车")]),
    _: 1
  })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_router_link, {
    to: "/order",
    class: "nav-item"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "coupon-o"
    }), _createTextVNode("订单")]),
    _: 1
  })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_router_link, {
    to: "/mine",
    class: "nav-item"
  }, {
    default: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "manager-o"
    }), _createTextVNode("我的")]),
    _: 1
  })])]);
}