import { showToast } from 'vant'
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/home/home.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/cart/cart.vue'),
    meta: { isAuth: true }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/order/order.vue'),
    meta: { isAuth: true }
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/mine/mine.vue'),
    meta: { isAuth: true }
  },
  {
    path: '/store',
    name: 'Store',
    component: () => import('../views/store/')
  },
  {
    path: '/createorder',
    component: () => import('../views/createOrder/'),
    meta: { isAuth: true }
  },
  {
    path: '/address',
    component: () => import('../views/address/'),
    meta: { isAuth: true }
  },
  {
    path: '/addressedit',
    component: () => import('../views/addressEdit/'),
    meta: { isAuth: true }
  },
  {
    path: '/userinfoedit',
    component: () => import('../views/userinfoedit/'),
    meta: { isAuth: true }
  },
  {
    path: '/login',
    component: () => import('../views/login/')
  },
  {
    path: '/register',
    component: () => import('../views/register/')
  },
  {
    path: '/swipe',
    component: () => import('../views/swipe/')
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../views/test/')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.isAuth) {
    if (localStorage.isLogin === 'login') {
      next()
    } else {
      next('/login')
      showToast('请先登录')
    }
  } else {
    next()
  }
})

export default router
