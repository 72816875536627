import { ADDCART } from './mutation-type'

export default {
  [ADDCART](state, value) {
    state.cartList = value
  },
  changeDelete(state) {
    state.isDelete = !state.isDelete
  },
  delete(state, value) {
    state.cartList = value
  },
  submitOrder(state, value) {
    state.orderList = value
  },
  addOrderListEnd(state) {
    state.orderListEnd = state.orderListEnd.concat(state.orderList)
  },
  addUserAddress(state, value) {
    if (value.isDefault) {
      state.userAddress.map((item) => {
        item.isDefault = false
      })
    }
    state.userAddress.push(value)
  },
  editUserAddress(state, value) {
    state.userAddress = state.userAddress.map((item) => {
      if (value.isDefault) {
        item.isDefault = false
      }
      if (value.id === item.id) {
        return value
      } else {
        return item
      }
    })
  },
  deletetUserAddress(state, value) {
    state.userAddress = state.userAddress.filter((item) => {
      return !(value.id === item.id)
    })
    if (value.isDefault) {
      state.userAddress[0].isDefault = true
    }
  },
  changeAddress(state, value) {
    state.userAddress.map((item) => {
      item.isUse = false
      if (value.id === item.id) {
        item.isUse = true
      }
    })
  }
}
