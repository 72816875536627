import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-02f27fe8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app"
};
import FooterVue from './components/Footer.vue';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, {
        class: "content"
      }), _withDirectives(_createVNode(FooterVue, null, null, 512), [[_vShow, _ctx.$route.path != '/store' && _ctx.$route.path != '/createorder' && _ctx.$route.path != '/address']])]);
    };
  }
};