import { createStore } from 'vuex'
import mutations from './mutations'
export default createStore({
  state: {
    cartList: [], // 购物车
    isDelete: true, // storeHeader编辑关联
    orderList: [],
    orderListEnd: [],
    userAddress: [
      {
        id: 1001,
        name: 'jason',
        tel: '15874859686',
        province: '广东省',
        city: '深圳市',
        county: '南山区',
        addressDetail: '深圳湾',
        isDefault: false
      },
      {
        id: 1002,
        name: 'allen',
        tel: '15874859685',
        province: '湖北省',
        city: '武汉市',
        county: '武昌区',
        addressDetail: '黄鹤楼',
        isDefault: true
      },
      {
        id: 1,
        name: '张三',
        tel: '13000000000',
        province: '浙江省',
        city: '杭州市',
        county: '西湖区',
        addressDetail: '文三路 138 号东方通信大厦 7 楼 501 室'
      },
      {
        id: 2,
        name: '李四',
        tel: '13100000000',
        province: '浙江省',
        city: '杭州市',
        county: '拱墅区',
        addressDetail: '莫干山路 50 号'
      }
    ]
  },
  mutations,

  actions: {},
  modules: {}
})
