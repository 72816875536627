export default {
  homeData: () => {
    return {
      code: 200,
      data: {
        big: [
          {
            name: '美食',
            icon: 'icon-meishi'
          },
          {
            name: '甜点饮品',
            icon: 'icon-tubiaov-daochushangchuan-'
          },
          {
            name: '超市便利',
            icon: 'icon-bianlidian'
          },
          {
            name: '生鲜果蔬',
            icon: 'icon-guoshushengxian'
          },
          {
            name: '买药',
            icon: 'icon-yaopin'
          }
        ],
        small_sort: [
          {
            name: '午餐',
            icon: 'icon-wucan'
          },
          {
            name: '买酒',
            icon: 'icon-pijiu_'
          },
          {
            name: '新鲜水果',
            icon: 'icon-icon-test'
          },
          {
            name: '汉堡披萨',
            icon: 'icon-hanbao'
          },
          {
            name: '休闲饮品',
            icon: 'icon-xiuxianyinpin'
          },
          {
            name: '夜宵',
            icon: 'icon-yexiao'
          },
          {
            name: '吐司',
            icon: 'icon-tusi'
          },
          {
            name: '跑腿',
            icon: 'icon-diandongche'
          },
          {
            name: '美人佳丽',
            icon: 'icon-kouhong'
          },
          {
            name: '全部分类',
            icon: 'icon-fenlei'
          }
        ],
        centent_nav_list: [
          {
            tab: '天天神券',
            data: [
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              },
              {
                pic: 'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fwww.cfcy168.com%2FUploadFiles%2F2020%2F2%2F15904074889874037.jpg&refer=http%3A%2F%2Fwww.cfcy168.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1645421933&t=66b58fbba9dce6f6b397e38820de24dc',
                title: '隆江猪脚饭',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              },
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              },
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              }
            ]
          },
          {
            tab: '减配送费',
            data: [
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              }
            ]
          },
          {
            tab: '点评高分',
            data: [
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              },
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              }
            ]
          },
          {
            tab: '会员满减',
            data: [
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              },
              {
                pic: 'https://img1.baidu.com/it/u=1599947592,1695977044&fm=253&fmt=auto&app=138&f=JPEG?w=640&h=440',
                title: '酸菜鱼',
                sales: '2888',
                price: '20',
                label: ['门店上新', '很下饭']
              }
            ]
          }
        ]
      }
    }
  }
}
