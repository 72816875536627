import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/base.css'
import 'amfe-flexible'
import 'vant/es/toast/style'
import './api/mock'

const app = createApp(App)

app.use(store).use(router).mount('#app')
